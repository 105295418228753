import Link from "next/link"
import React from "react"
import routes from "routes"

export type PageNotFoundProps = {
  message?: string
  tieupId?: number
}

const PageNotFound: React.FC<PageNotFoundProps> = ({
  message = "お探しのページは、一時的にアクセス出来ない状態か移動もしくは削除された可能性があります。",
  tieupId,
}) => {
  return (
    <main>
      <div className="error l-single -small">
        <p className="error-title">
          <span>404</span> Page not found
        </p>
        <p className="error-subtitle">お探しのページは見つかりませんでした。</p>
        <p className="error-text">
          <span>{message}</span>
        </p>
        <p className="error-link">
          {tieupId ? (
            <Link href={routes.creatorMyPageTieup(tieupId)}>
              <a className="c-textLink-gray">目次へ戻る</a>
            </Link>
          ) : (
            <a className="c-textLink-gray" href="/">
              トップページへ戻る
            </a>
          )}
        </p>
      </div>
    </main>
  )
}

export default PageNotFound
