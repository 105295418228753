import GlobalFooter from "src/components/common/GlobalFooter"
import PageNotFound from "src/components/pages/PageNotFound"

export default function Custom404() {
  return (
    <>
      <PageNotFound />
      <GlobalFooter />
    </>
  )
}
